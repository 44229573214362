import Timer from './Components/Timer'
import './App.css'
import { AiFillInstagram } from 'react-icons/ai';
import { AiOutlineMail } from 'react-icons/ai';

function App() {
  return (
    <div className="App">
      <Timer />
      <div className="floatInsta">
        <AiFillInstagram size={30} onClick={() => window.open("https://www.instagram.com/ungodsofficial/", "_blank")}/>
      </div>
      <div className="floatMail">
        <AiOutlineMail size={30} onClick={() => window.open('mailto:ungods7band@gmail.com', "_blank")} />
      </div>
    </div>
  );
}

export default App;
